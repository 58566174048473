import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from '../model/product';
import { User } from '../model/user';
import { ClientsService } from '../services/catalogs/clients.service';
import { ProductsService } from '../services/catalogs/products.service';
import { LoginService } from '../services/login.service';
import { ConfigCompanyUsecase } from '../use-cases/config-company-usecase';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  sitrackDomainRegex ='^[A-Za-z0-9._%+-]+@sitrack\.com$'

  @ViewChild('emailForm') emailForm!:FormControl;

  email: string = '';
  password: string = '';

  error: any
  message: any;

  recovery: boolean = false;

  screenHeight: number;

  sysVersion: string = environment.version;

  sysEnviromentProd: boolean = environment.production;

  sessionToken:string;

  constructor(public loginService: LoginService, private router: Router, private auth :AngularFireAuth,
    private clientsService: ClientsService, private productsService: ProductsService,
    private _configCompanyUsecase: ConfigCompanyUsecase, private _toasterService:ToastrService) {

    this.screenHeight = window.innerHeight - 100;
    localStorage.setItem(environment.sessionWorkCompany, 'sitrack');   
     this.sessionToken = '';
  }

  ngOnInit(): void {
    this.validSessionToken();
  }

  async validSessionToken(){
  let sessionResult = await this.auth.idToken;
  sessionResult.subscribe(token=>{
    this.sessionToken = token!;
  });
  }

  login() {
    const infoAccess = this._toasterService.info('Validando credenciales, un momento..','Acceso.', {
      timeOut: 0,
    })
    //console.log(this.email+' - '+this.password);
    this.loginService.login(this.email, this.password).then((response: any) => {
      //console.log(response.user);
      localStorage.setItem(environment.sessionID, response.user.uid);
      this.loginService.user = response.user;
      let loginSubs = this.loginService.getUserInfoById(response.user.uid).subscribe((userDoc: User) => {
        this._toasterService.remove(infoAccess.toastId);
        this._toasterService.success('Redireccionando...', 'Acceso Exitoso',);
        const user = userDoc as User;
        this.loginService.sessionUser = user.name;
        this.loginService.sessionUserPosition = user.position;
        localStorage.setItem(environment.sessionActualUser, user.name);
        localStorage.setItem(environment.sessionUserPosition, user.position);
        localStorage.setItem(environment.sessionEmployeeSAE, user.cveSAE);
        localStorage.setItem(environment.sessionWorkCompany, user.company);
        this.preload();
        loginSubs.unsubscribe();
        this.router.navigate(['/workorders']);
      });
      this._configCompanyUsecase.getAndLoadConfigs();
    }).catch((err: { message: any; }) => {
      this._toasterService.error(err.message, 'Error de Autenticación', {
        timeOut:50000
      });
      this.error = err.message;
    });
  }

  openRecovery() {
    this.recovery = true;
    this.loginService.error = null;
    this.message = null;
  }

  cancelRecovery() {
    this.recovery = false;
    this.loginService.error = null;
    this.message = null;
  }

  sendReset() {
    try {
      this.loginService.sendRecoveryEmail(this.email);
      this.message = 'Se ha enviado un correo para generar una nueva contraseña.'
      this.recovery = false;
    } catch (e) {
      this.error = e;
    }
  }

  /**
   * Metodo para cargar los catalgos previos a local storage.
   */
  preload() {
    const customers = localStorage.getItem('customers');
    if (!customers) {
      let clientSubs = this.clientsService.getClientsAllFS().subscribe((clientDocs: any) => {
        console.log(clientDocs);
        localStorage.setItem(environment.sessionCustomer, JSON.stringify(clientDocs));
        clientSubs.unsubscribe();
      });

    }
    const products = localStorage.getItem('products');
    if (!products) {

      let productSubs = this.productsService.getProductsNPricesFS().subscribe((productsDocs: string | any[]) => {
        const arrayProduct: Array<Product> = [];
        for (let index = 0; index < productsDocs.length; index++) {
          const product = productsDocs[index];
          this.productsService.getProductPricesByProductIdFS(product.id).forEach((prices: any) => {
            console.log(prices)
            product.priceList = prices
        });
          arrayProduct.push(product);
        }
        localStorage.setItem(environment.sessionProducts, JSON.stringify(arrayProduct));
        productSubs.unsubscribe();
      });

    }
  }

}
