<div class="container-full p-5" [ngStyle]="{ height: screenHeight + 'px' }">
  <div
    class="row justify-content-center align-items-center"
    [ngStyle]="{ height: screenHeight + 'px' }"
  >
    <div class="col-12 col-sm-10 col-md-4 col-lg-3 col-xl-3">
      <div class="card mb-3">
        <div class="card-body text-center">
          <!--img src="assets/images/top-login.png" alt="Sitrack OT logo" class="mb-3" style="width: 100%;"-->
          <img
            src="assets/images/logo.png"
            alt="Sitrack OT logo"
            class="mt-3 mb-3"
            style="width: 80%"
          />

          <form action="">
            <div class="form-floating mt-3 mb-3">
              <input
                type="email"
                class="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                [(ngModel)]="email"
                [ngModelOptions]="{ standalone: true }"
              />
              <label for="floatingInput">Correo Electrónico</label>
            </div>
            <div class="form-floating mb-3" *ngIf="!recovery">
              <input
                type="password"
                class="form-control"
                id="floatingPassword"
                placeholder="Password"
                [(ngModel)]="password"
                [ngModelOptions]="{ standalone: true }"
              />
              <label for="floatingPassword">Password</label>
            </div>
            <div
              class="alert alert-danger"
              role="alert"
              *ngIf="loginService.error!"
            >
              {{ loginService.error }}
            </div>
            <div class="alert alert-success" role="alert" *ngIf="message">
              {{ message }}
            </div>
            <div class="col-auto text-end" *ngIf="!recovery">
              <div class="btn-group" role="group">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  (click)="openRecovery()"
                >
                  <i class="fa-solid fa-question"></i> Recuperar
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  (click)="login()"
                  (keyup.enter)="login()"
                >
                  <i class="fa-solid fa-arrow-right-to-bracket"></i> Entrar
                </button>
              </div>
            </div>
            <div class="col-auto text-end" *ngIf="recovery">
              <button
                type="button"
                class="btn btn-outline-secondary"
                (click)="cancelRecovery()"
              >
                <i class="fa-solid fa-xmark"></i> Cancelar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                (click)="sendReset()"
              >
                <i class="fa-solid fa-envelope"></i> Enviar el reseteo
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row" style="color: #fff">
          <div class="col-6 text-primary text-center" *ngIf="sysEnviromentProd">
            <h6 class="m-2">
              <i class="bi bi-shield-fill-check"></i> PRODUCCION
            </h6>
          </div>
          <div
            class="col-6 text-secondary text-center"
            *ngIf="!sysEnviromentProd"
          >
            <h6 class="m-2"><i class="bi bi-hexagon"></i> PRUEBAS</h6>
          </div>
          <div class="col-6 text-primary text-center">
            <h6 class="m-2">
              <i class="bi bi-wrench-adjustable-circle-fill"></i>
              {{ sysVersion }}
            </h6>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-10 col-md-4 col-lg-3 col-xl-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <h6>Funcionalidad y cambios.</h6>
            <ul>
              <strong>26/08/2024</strong>
              <li>Integración de Retención RESICO en facturación.</li>
              <li>Integración de Descuento en facturación.</li>
              <li>Implementacion de utileria para converitr numero a letras.</li>
              <li>Ajustes de plantillas en prefactura y factura.</li>

              <strong>19/08/2024</strong>
              <li>Carga de pagos bancarios por plantilla.</li>
              <li>Ajuste de desgloses en bloques de facturación.</li>
              <li>Filtro por cliente en listado de Seguimiento CxC.</li>
              
              <!--strong>08/08/2024</strong>
              <li>Ajustes de factura y descarga de archivo en Seguimiento CxC.</li>
              <li>Nota de cliente con aduedos en factura</li>
              <li>Corrección de listado de clientes en ventana de registro de pago bancario.</li>
              <li>Ajuste de Fecha tentativa de Pago en factura.</li-->
              <!--strong>05/08/2024</strong>
              <li>Filtro por periodo o cliente en pagos bancarios.</li>
              <li>Asignación de Pago bancario sin cliente desde Complemento de Pago</li>
              <li>Ajuste de productos en Carga Masiva de unidades.</li>
              <li>Banderas de Instalacion de Unidad y Producto en Desgloses de Orden.</li-->
              <!--strong>03/07/2024</strong>
              <li>Revision de cálculos dentro del monitor de facturación.</li>
              <li>Asignacion de ejecutivo de Cuenta por cobrar en la edición de información de cliente.</li>
              <li>Seguimiento de Cuenta x Cobrar en la factura y en las opciones del estado de cuenta.</li>
              <li>Notificación de Renovación en la pantalla de Ordenes por vencer.</li-->
              <!--strong>13/06/2024</strong>
              <li>Ajuste en la exportacion de facturas, usuario que emite resuelto y fecha de emisión agregada.</li>
              <li>Boton de Renovacion en listado de ordenes.</li>
              <li>Ajuste en presentacion de ordenes vencidas.</li-->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
