import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component:LoginComponent},
  { path: 'workorders', loadChildren:() => import('./workorders/workorders.module').then(m => m.WorkordersModule), },
  { path: 'catalogs', loadChildren:() => import('./catalogs/catalogs.module').then(m => m.CatalogsModule), },
  { path: 'bank', loadChildren:() => import('./bank/bank.module').then(m => m.BankModule), },
  { path: 'exports', loadChildren:() => import('./exports/exports.module').then(m => m.ExportsModule), },
  { path: 'security', loadChildren:() => import('./security/security.module').then(m => m.SecurityModule), },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
